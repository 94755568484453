<template>
  <v-card width="100%" class="grey lighten-3 pa-5 mb-5">
    <v-form lazy-validation ref="form" v-on:submit.prevent>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="data.address"
            :label="$t('address')"
            :rules="[(v) => !!v || $t('required.field')]"
            :readonly="isView"
          ></v-text-field>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="data.start_date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="data.start_date"
                :label="$t('adminCourses.startDate')"
                v-bind="attrs"
                v-on="on"
                :rules="[(v) => !!v || $t('required.field')]"
                :readonly="isView"
              ></v-text-field>
            </template>
            <v-date-picker v-model="data.start_date" no-title scrollable :color="this.primaryColor" :readonly="isView">
              <v-spacer></v-spacer>
              <v-btn text @click="menu1 = false">
                {{ $t("form.cancel") }}
              </v-btn>
              <v-btn color="#69d1b6" @click="$refs.menu1.save(data.start_date)" dark>
                {{ $t("form.ok") }}
              </v-btn>
            </v-date-picker>
          </v-menu>
            <v-radio-group
              v-if="!data.id"
              column
              :readonly="isView"
              :label="$t('adminCourses.courseLanguage') + ': '"
              v-model="data.default_lang"
              :rules="[(v) => !!v || this.$t('required.field')]"
              class="mb-6 mt-0"
            >
              <v-radio
                v-for="(lang, index) in languages"
                :key="index"
                :label="lang.label"
                :value="lang.value"
              ></v-radio>
            
            </v-radio-group>
            <!-- <v-text-field
            v-model="data.ar_address"
            :label="$t('newAdminCourses.ar_address')"
          ></v-text-field> -->
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
            :readonly="isView"
              type="number"
              v-model="data.capacity"
              :label="$t('adminCourses.capacity')"
              :rules="[(v) => !!v || $t('required.field')]"
            ></v-text-field>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="data.end_date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="data.end_date"
                :label="$t('adminCourses.endDate')"
                v-bind="attrs"
                :readonly="isView"
                v-on="on"
                :rules="[(v) => !!v || $t('required.field')]"
              ></v-text-field>
            </template>
            <v-date-picker v-model="data.end_date" no-title scrollable :color="this.primaryColor">
              <v-spacer></v-spacer>
              <v-btn text @click="menu2 = false">
                {{ $t("form.cancel") }}
              </v-btn>
              <v-btn color="#69d1b6" @click="$refs.menu2.save(data.end_date)" dark>
                {{ $t("form.ok") }}
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-textarea 
            :label="$t('adminCourses.description')" 
            height="50"
            class="mt-4"
            :readonly="isView"
            v-model="data.description" 
            :rules="[(v) => !!v || $t('required.field')]"
          ></v-textarea>
          <!-- <v-textarea 
            :label="$t('newAdminCourses.ar_description')" 
            height="50"
            class="mt-4"
            v-model="data.ar_description" 
          ></v-textarea> -->
        </v-col>
      </v-row>
      
      

      <div class="d-flex justify-center" v-if="!isView">
        <base-button
          class="text-large mx-5"
          :options="{ block: false, isLoading: btnLoading }"
          @click="save"
        >
          {{ this.$t("form.save") }}
        </base-button>

        <!-- <v-btn
          v-if="this.data.id"
          class="text-large roundedBtn white--text"
          :disabled="this.index === 0"
          :color="this.RColor"
          @click="deleteConfirm = data.id"
        >
          {{ this.$t("form.delete") }}
        </v-btn> -->

        <base-button
         v-if="!data.id"
          class="text-large"
          :options="{ block: false, color: this.YColor }"
          @click="$emit('cancel', {index: index})"
        >
          {{ this.$t("form.cancel") }}
        </base-button>
      </div>
    </v-form>
    <delete-courses-confirm
     v-if="deleteConfirm>=0"
      v-bind="{ id: deleteConfirm, isOpen: true }"
      @handleCancel="deleteConfirm = -1"
      @handelRequest="remove"
    ></delete-courses-confirm>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButton from "../../../../../core/Base/Buttons/BaseButton";
import deleteCoursesConfirm from "../../../Components/confirmDelete"

export default {
  name: "OfflineForm",

  components: { 
    BaseButton,
    deleteCoursesConfirm
  },
  
  props: [
    'data',
    'courseId',
    'index',
    'isView',
  ],
  data() {
    return {
      deleteConfirm: -1,
      btnLoading: false,
      date1: null, // for the date picker functionality only
      date2: null, // for the date picker functionality only
      menu1: null, // for the date picker functionality only
      menu2: null, // for the date picker functionality only
      languages: [
        { label: this.$t("langs.arabic"), value: "ar" },
        { label: this.$t("langs.english"), value: "en" },
      ],
      tempId: -1,
    };
  },
  methods: {
    ...mapActions("Courses", ["addOfflineCourse", "updateOfflineCourse", "deleteOfflineCourse", "translateOfflineCourse"]),
    ...mapActions(["addOfflineInfo", "updateOfflineInfo"]),

    save () {
      console.log('flag')
      console.log(this.data)
      if (this.data.id) {
        this.tempId = this.data.id;
        this.data.language = this.data.default_lang;
        this.updateOfflineInfo({data: this.data, id: this.data.id }).then((res) => {
          console.log(res)
          if (res.data) {
            if (res.data.status === 'Success')
              this.data.id = this.tempId
          }
          // this.translateOfflineCourse(this.data)
          this.$emit('prepare')
        })
      } else {
        this.data.language = this.data.default_lang;
        this.data.default_lang = undefined; // because it is not allowed in the post
        this.addOfflineInfo(this.data).then(() => {
          // this.data.id = res.data.response.id;
          // this.translateOfflineCourse(this.data)
          this.$emit('prepare')
        })
      }
    },
    remove () {
      this.deleteOfflineCourse(this.data.id).then(() => {
        this.deleteConfirm = -1;
        this.$emit('prepare', { add: false })
      })
    }
  }
};
</script>
<style scoped>
.roundedBtn {
  border-radius: 20px !important;
}
</style>