<template>
  <div class="uk-container">
    <v-row class="uk-container">
      <h1 class="font-weight-bold" :style="{ color: this.fontWeightBold }">
        <base-arrow-back-icon @click="$router.go(-1)">{{
          rightArrowIcon
        }}</base-arrow-back-icon>

        <span>معلومات الحضور </span>
      </h1>
    </v-row>
    <v-row justify="center">
      <div
        class="w-100 mt-5"
        v-if="getCourse.type == 'offline' && this.$route.params.id > 0"
      >
        <OfflineForm
          v-for="(course, index) in this.getCourse.offlineCourses"
          :key="course.id"
          :data="course"
          :courseId="getCourse.course_id"
          :index="index"
          :isView="false"
          :languages="languages"
          @close="$emit('handleClose')"
          @prepare="prepareOffline"
          @cancel="cancelNewOffline"
        />
        <base-button
          class="text-large mb-3"
          :options="{
            block: true,
            isLoading: btnLoading,
            color: this.darkColor,
          }"
          @click="addNewCourse()"
        >
          {{ this.$t("adminCourses.newOffline") }}
        </base-button>
      </div>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import BaseArrowBackIcon from "@/core/Base/Arrows/BaseArrowBackIcon";
import BaseButton from "../../../../../core/Base/Buttons/BaseButton";
import OfflineForm from './OfflineForm';
export default {
  components: { BaseArrowBackIcon, BaseButton, OfflineForm },
  created () {
    this.init()
  },
  data () { 
    return {
      btnLoading: false,
      languages: [
        { label: this.$t("langs.arabic"), value: "ar" },
        { label: this.$t("langs.english"), value: "en" },
        // { label: this.$t("langs.mix"), value: "mix" },
      ],
    }
  },
  
  methods: {
    ...mapActions("Courses", [
      "addOfflineCourse",
      "updateOfflineCourse",
      "fetchOfflineData",
    ]),
    ...mapActions(["fetchSingleCourses"]),
    init() {
      // if (this.$route.params.id > 0) {
        // if it is edit not create

        this.fetchSingleCourses(this.$route.params.id).then(() => {
          console.log(this.getCourse.offlineCourses)
        });
      // }
    },
    

    prepareOffline() {
      this.fetchSingleCourses(this.$route.params.id).then(() => {
        this.fetchSingleCourses(this.$route.params.id)
      });
    },

    addNewCourse() {
      this.isCreate = true;
      let newCourse = {
        courseId: this.$route.params.id.toString(),
        address: "",
        capacity: 0,
        description: "",
        start_date: null,
        end_date: null,
      };
      this.getCourse.offlineCourses.push(newCourse);
    },

    /**
     * delete the new offline data that has not been uploaded yet
     */
    cancelNewOffline(payload) {
      this.getCourse.offlineCourses.splice(payload.index, 1);
    },
  },
  computed: {
    ...mapGetters(["getCourse"]),
  },
};
</script>